import * as React from "react";
const SvgChemtech = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 100 100", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,-731,0)" }, /* @__PURE__ */ React.createElement("g", { id: "chemtech", transform: "matrix(14.641,0,0,11.5354,518.821,-120.202)" }, /* @__PURE__ */ React.createElement("rect", { x: 14.492, y: 10.42, width: 6.83, height: 8.669, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { id: "Layer2", transform: "matrix(0.100592,0,0,0.127675,-60.6768,8.33318)" }, /* @__PURE__ */ React.createElement("path", { d: "M777.252,81.695C777.252,81.695 776.454,66.178 766.276,57.131C762.678,53.933 751.446,51.461 751.17,61.829C751.057,66.076 754.528,71.063 758.503,73.051C762.414,75.006 758.914,70.452 762.792,70.295C765.721,70.177 776.891,80.817 777.252,81.695Z", style: {
  fill: "none",
  stroke: "rgb(115,224,0)",
  strokeWidth: "2.04px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M780.343,70.488C779.05,69.04 770.744,50.863 763.633,48.232C756.522,45.601 761.8,29.601 764.21,33.157C766.62,36.712 767.169,38.803 768.966,34.65C772.166,27.255 778.281,19.006 785.179,20.286C796.742,22.432 775.937,25.425 784.539,26.757C789.587,27.539 801.462,34.081 802.245,43.325C803.027,52.569 799.485,43.163 795.845,41.69C792.858,40.481 798.704,46.166 792.361,50C786.626,53.467 783.082,47.734 781.393,46.312C778.591,43.953 788.019,43.632 786.388,40.197C785.037,37.352 781.661,35.575 778.39,36.641C775.031,37.736 771.811,38.988 773.09,45.103C774.37,51.218 782.334,56.196 783.401,60.178C784.468,64.16 782.121,72.479 780.343,70.488Z", style: {
  fill: "none",
  stroke: "rgb(115,224,0)",
  strokeWidth: "2.04px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M783.899,78.452C783.899,78.452 790.269,52.351 802.354,53.138C809.995,53.636 810.777,62.311 810.422,64.089C810.066,65.866 807.725,60.391 802.354,60.675C796.983,60.96 783.899,78.452 783.899,78.452Z", style: {
  fill: "none",
  stroke: "rgb(115,224,0)",
  strokeWidth: "2.04px"
} }), /* @__PURE__ */ React.createElement("path", { d: "M791.365,74.684C791.365,74.684 800.025,62.474 804.985,65.786C806.582,66.853 806.981,69.638 806.084,70.151C804.935,70.81 803.169,71.128 800.467,69.564C797.87,68.06 791.365,74.684 791.365,74.684Z", style: {
  fill: "none",
  stroke: "rgb(115,224,0)",
  strokeWidth: "2.04px"
} })))));
export default SvgChemtech;
