import { toolState, useSettingsSnapshot } from "@shared/states/uiStates";
import React, { lazy, PropsWithChildren, Suspense, useRef, useState } from "react";
import { Viewer, ViewerRef, Viewport } from "./Viewer";

import { MapContent } from "./MapContent";
import { useAutoFit, useVTM } from "./hooks";
import { getPointFromScreenCoordinates } from "./utils";
import { pointerState } from "@shared/states/mapStates";
import { ref, useSnapshot } from "valtio";
import { MapTool } from "@shared/types/tools";

const Rift = lazy(() => import("@shared/Map/objects/rifts/Rift"));
const VectorRift = lazy(() => import("@shared/Map/objects/rifts/VectorRift"));

export interface SvgMapProps {}

export const RIFT_SIZE = 15100 * 0.9860985244913679;

const Map: React.FC<PropsWithChildren<SvgMapProps>> = (props) => {
	const settingsSnap = useSettingsSnapshot();
	const viewerRef = useRef<ViewerRef | null>(null);
	const [viewport, setViewport] = useState<Viewport>({ x: 0, y: 0, scale: 1 });
	const toolSnap = useSnapshot(toolState);
	const { vtm, viewportGroupRef } = useVTM(viewport);

	useAutoFit(viewerRef);

	const handleViewportChange = (newViewport: Viewport) => {
		setViewport(newViewport);
	};

	const handleMouseMove = (e: React.MouseEvent) => {
		if (!vtm) return;
		const screenPoint = getPointFromScreenCoordinates(vtm, e.clientX, e.clientY);
		const { x, y } = screenPoint;
		pointerState.position = { x, y };
		const point = viewerRef.current?.svg()?.createSVGPoint();
		if (!point) return;
		point.x = x;
		point.y = y;
		pointerState.ref = ref(viewerRef.current?.svg()!);
	};

	return (
		<Viewer
			className={"w-full h-full"}
			ref={viewerRef}
			viewport={viewport}
			onViewportChange={handleViewportChange}
			onMouseMove={handleMouseMove}
			canPan={toolState.selectedTool === MapTool.PAN}
			options={{
				scaleFactor: 0.08,
				stageHeight: RIFT_SIZE,
				stageWidth: RIFT_SIZE,
				padding: 100,
				maxScale: 8,
				minScale: 0.03,
			}}
		>
			<defs>
				{/*used for champion portraits*/}
				<clipPath id={"circle-clip"}>
					<circle cx={0} cy={0} r={400} />
				</clipPath>
			</defs>
			<g ref={viewportGroupRef}>
				<Suspense>
					{settingsSnap.map?.vectorStyle !== true ? <Rift /> : <VectorRift />}
				</Suspense>
				<MapContent viewerRef={viewerRef} viewport={viewport} />
			</g>
		</Viewer>
	);
};

export { Map };
