import * as React from "react";
const SvgBaronHunter = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 115 100", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("rect", { id: "baron-hunter", x: 0, y: 0, width: 115, height: 100, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { id: "baron-hunting" }, /* @__PURE__ */ React.createElement("path", { id: "baron-pit", "serif:id": "baron pit", d: "M35.899,47.09l-0.009,-0.006c-0,0 -1.305,4.975 -0.714,9.109c1.017,7.121 -0.423,8.634 -6.222,3.843c-4.9,-4.048 -9.938,-6.311 -17.353,-25.506c-0.669,-1.732 1.578,-5.44 3.383,-5.872c8.023,-1.922 18.574,-1.819 22.408,-14.463c21.357,-0.063 33.678,10.112 39.28,22.846c6.736,15.314 1.011,28.075 16.806,38.731c0.503,0.339 -5.188,10.134 -7.462,10.02c-21.98,-1.099 -25.468,-4.45 -32.427,-8.371c-1.767,-0.995 -5.511,-3.503 -5.775,-6.095c-0.288,-2.832 2.862,-1.942 7.438,-3.045c2.438,-0.587 5.328,-2.68 6.226,-3.363c4.244,-3.004 7.017,-7.952 7.017,-13.542c0,-9.15 -7.428,-16.578 -16.578,-16.578c-7.668,-0 -14.127,5.217 -16.018,12.292Z", style: {
  fill: "#101800",
  fillOpacity: 0,
  stroke: "#cc00cf",
  strokeWidth: 3
} })));
export default SvgBaronHunter;
