import * as React from "react";
const SvgFire = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 100 100", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,-365,0)" }, /* @__PURE__ */ React.createElement("g", { id: "fire", transform: "matrix(14.641,0,0,11.5354,152.821,-120.202)" }, /* @__PURE__ */ React.createElement("rect", { x: 14.492, y: 10.42, width: 6.83, height: 8.669, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.0810679,0,0,0.102894,-5.47963,9.47448)" }, /* @__PURE__ */ React.createElement("g", { id: "Layer1" }, /* @__PURE__ */ React.createElement("path", { d: "M286.623,13.516C288.552,14.212 300.145,23.769 297.954,30.094C295.509,37.15 275.598,40.968 271.954,55.855C270.035,63.692 283.624,76.863 289.368,77.737C295.113,78.612 305.06,65.624 306.421,61.102C307.742,56.714 300.261,51.616 297.534,50.611C294.811,49.607 290.383,53.339 290.055,55.068C289.727,56.796 295.683,58.805 295.568,60.983C295.454,63.162 291.932,68.556 289.368,68.139C286.804,67.722 280.183,62.293 280.183,58.479C280.183,54.664 285.194,47.699 289.368,45.254C293.542,42.809 301.286,42.27 305.229,43.809C309.171,45.349 311.225,52.65 313.024,54.49C313.727,55.21 315.432,55.669 316.02,54.853C316.887,53.649 317.452,46.491 318.228,47.268C319.005,48.045 321.041,55.504 320.681,59.514C320.27,64.072 319.541,70.042 313.477,70.954C304.953,72.236 292.757,89.956 288.175,89.607C286.132,89.451 283.365,86.431 278.267,84.144C274.777,82.578 270.215,81.506 268.175,79.236C266.214,77.054 266.095,73.274 264.528,69.336C263.125,65.805 260.348,62.194 257.523,58.717C253.827,54.167 259.384,52.519 258.835,50C257.886,45.649 259.909,37.653 261.697,38.919C263.303,40.057 264.642,44.896 266.706,43.809C270.821,41.643 283.065,30.968 286.385,25.92C288.657,22.464 284.695,12.82 286.623,13.516Z", style: {
  fill: "none",
  stroke: "rgb(229,149,53)",
  strokeWidth: "2.53px"
} }))))));
export default SvgFire;
