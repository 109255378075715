import * as React from "react";
const SvgOcean = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 100 100", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,-490,0)" }, /* @__PURE__ */ React.createElement("g", { id: "ocean", transform: "matrix(14.641,0,0,11.5354,277.821,-120.202)" }, /* @__PURE__ */ React.createElement("rect", { x: 14.492, y: 10.42, width: 6.83, height: 8.669, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.0869193,0,0,0.110321,-17.9929,9.31818)" }, /* @__PURE__ */ React.createElement("g", { id: "Layer1" }, /* @__PURE__ */ React.createElement("path", { d: "M401.952,48.444C401.506,49.492 408.897,45.159 414.632,46.017C420.379,46.877 425.041,52.446 425.993,55.877C427.63,61.777 424.051,73.667 418.241,77.643C412.379,81.654 399.233,80.977 393.046,75.854C387.823,71.529 384.162,65.239 381.865,58.858C379.008,50.921 380.878,31.242 388.774,27.323C391.673,25.884 387.249,38.319 390.363,37.242C393.477,36.164 401.199,23.715 407.46,20.857C413.672,18.022 421.55,18.335 427.931,20.098C434.371,21.877 441.723,33.549 443.002,40.982C443.617,44.552 440.046,48.203 440.454,51.546C440.862,54.889 445.575,58.045 445.45,61.042C445.324,64.04 443.242,68.029 439.702,69.53C436.726,70.793 433.57,77.558 433.298,76.003C433.026,74.448 436.912,65.58 438.068,60.2C439.181,55.028 436.053,46.538 430.04,41.758C427.011,39.35 422.795,38.213 422.172,36.264C421.678,34.719 424.981,33.518 426.671,31.753C428.556,29.784 428.825,27.339 427.622,27.534C426.354,27.739 420.89,27.913 416.511,30.493C413.012,32.555 410.856,37.528 409.325,38.864C405.512,42.19 403.356,45.141 401.952,48.444Z", style: {
  fill: "none",
  stroke: "rgb(0,255,206)",
  strokeWidth: "2.36px"
} }))))));
export default SvgOcean;
