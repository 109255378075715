import * as React from "react";
const SvgBaronSeeing = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 115 100", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("rect", { id: "baron-seeing", x: 0, y: 0, width: 115, height: 100, style: {
  fill: "#101800",
  fillOpacity: 0
} }), /* @__PURE__ */ React.createElement("g", { id: "baron-seeing1", "serif:id": "baron-seeing" }, /* @__PURE__ */ React.createElement("path", { d: "M30.43,20.564l8.301,-9.105c0,-0 24.906,3.213 34.279,14.461c9.373,11.248 2.142,18.21 5.088,23.566c2.946,5.356 16.068,25.441 16.068,25.441l-7.498,2.791c-0,-0 -1.607,-6.272 -8.838,-15.109c-7.23,-8.838 -13.885,-9.175 -13.885,-9.175c-0,-0 6.241,-13.526 -2.318,-20.413c-9.492,-7.639 -15.397,-3.62 -21.021,-1.477c-5.624,2.142 -10.176,-10.98 -10.176,-10.98Z", style: {
  fill: "#101800",
  fillOpacity: 0,
  stroke: "#cc00cf",
  strokeWidth: 3
} }), /* @__PURE__ */ React.createElement("path", { d: "M29.319,41.998c6.16,8.837 7.718,9.198 3.701,11.267c-4.017,2.069 -5.507,-3.618 -10.327,-7.903c-4.821,-4.285 -15.279,-12.586 -9.655,-16.068c5.623,-3.481 10.122,3.866 16.281,12.704Z", style: {
  fill: "#101800",
  fillOpacity: 0,
  stroke: "#cc00cf",
  strokeWidth: 3
} }), /* @__PURE__ */ React.createElement("path", { d: "M64.708,67.965c8.302,6.695 13.034,13.73 8.482,15.873c-4.553,2.142 -3.146,-4.785 -14.125,-10.283c-10.98,-5.498 -14.79,-6.772 -13.522,-11.209c1.12,-3.916 10.863,-1.076 19.165,5.619Z", style: {
  fill: "#101800",
  fillOpacity: 0,
  stroke: "#cc00cf",
  strokeWidth: 3
} })));
export default SvgBaronSeeing;
