import * as React from "react";
const SvgAtakhanV = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 100 100", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,-452,-170)" }, /* @__PURE__ */ React.createElement("g", { id: "atakhan-v", transform: "matrix(14.641,0,0,11.5354,239.821,49.798)" }, /* @__PURE__ */ React.createElement("rect", { x: 14.492, y: 10.42, width: 6.83, height: 8.669, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { id: "Layer1" }), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.0886423,0,0,0.112507,-26.6722,-9.95788)" }, /* @__PURE__ */ React.createElement("path", { d: "M502.932,187.253L508.715,199.38L502.932,211.741L497.054,198.331L502.932,187.253Z", style: {
  fill: "rgb(189,59,13)",
  stroke: "black",
  strokeWidth: "1.54px"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.0886423,0,0,0.112507,-26.6722,-9.95788)" }, /* @__PURE__ */ React.createElement("path", { d: "M509.881,208.476C509.151,202.768 513.682,195.051 519.326,196.931C519.842,196.416 518.789,201.013 518.789,201.013C518.789,201.013 522.587,203.079 523.469,204.994C525.416,209.219 524.457,215.589 524.457,215.589C522.366,210.878 519.829,206.613 515.245,204.394L509.881,208.476Z", style: {
  fill: "rgb(189,59,13)",
  stroke: "black",
  strokeWidth: "1.54px"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(-0.0886423,0,0,0.112507,62.4856,-9.95788)" }, /* @__PURE__ */ React.createElement("path", { d: "M509.881,208.476C509.151,202.768 513.682,195.051 519.326,196.931C519.842,196.416 518.789,201.013 518.789,201.013C518.789,201.013 522.587,203.079 523.469,204.994C525.416,209.219 524.457,215.589 524.457,215.589C522.366,210.878 519.829,206.613 515.245,204.394L509.881,208.476Z", style: {
  fill: "rgb(189,59,13)",
  stroke: "black",
  strokeWidth: "1.54px"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.0886423,0,0,0.112507,-26.6823,-9.84537)" }, /* @__PURE__ */ React.createElement("path", { d: "M504.114,220.09C505.227,215.023 514.072,211.741 517.248,211.741C520.425,211.741 519.893,219.449 523.174,220.09C526.455,220.732 538.337,210.971 536.934,215.589C535.516,220.261 518.374,244.916 514.662,248.123C511.309,251.02 516.42,235.89 514.662,234.83C512.904,233.769 505.258,244.557 504.114,241.761C503.194,239.514 502.957,225.352 504.114,220.09ZM508.445,230.236L519.042,225.161L519.192,220.534L515.46,220.757L508.669,226.429L508.445,230.236Z", style: {
  fill: "rgb(189,59,13)",
  stroke: "black",
  strokeWidth: "1.54px"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(-0.0886423,0,0,0.112507,62.4856,-9.84537)" }, /* @__PURE__ */ React.createElement("path", { d: "M502.864,220.09C502.55,225.36 502.502,239.54 502.816,241.79C503.252,244.907 512.688,233.774 514.662,234.83C516.636,235.885 511.309,251.02 514.662,248.123C518.374,244.916 535.516,220.261 536.934,215.589C538.337,210.971 526.455,220.732 523.174,220.09C519.893,219.449 520.633,211.741 517.248,211.741C513.863,211.741 503.194,214.556 502.864,220.09ZM507.336,230.236C506.075,230.39 506.804,227.451 507.56,226.429C508.729,224.85 512.598,221.74 514.351,220.757C515.438,220.148 517.486,219.8 518.083,220.534C518.68,221.267 519.079,224.127 517.934,225.161C516.142,226.778 509.065,230.024 507.336,230.236Z", style: {
  fill: "rgb(189,59,13)",
  stroke: "black",
  strokeWidth: "1.54px"
} })))));
export default SvgAtakhanV;
