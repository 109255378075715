import * as React from "react";
const SvgBaronTerritorial = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 115 100", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("rect", { id: "baron-territorial", x: 0, y: 0, width: 115, height: 100, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { id: "baron-territoral" }, /* @__PURE__ */ React.createElement("path", { d: "M36.322,13.001c0,0 29.397,4.483 37.485,22.207c8.088,17.725 1.406,19.093 6.568,27.353c5.163,8.26 12.562,18.068 12.562,18.068l-8.088,9.293c0,-0 -9.912,-0.67 -18.412,-6.711c-4.595,-3.266 -7.659,-6.228 -8.26,-10.153c31.87,-30.257 -20.542,-52.562 -26.872,-23.575c-2.382,5.162 -15.438,-0.642 -19.934,-7.4c-2.329,-3.5 -0.686,-8.318 1.548,-9.981c2.157,-1.604 10.325,-0.86 15.488,-6.367c5.162,-5.506 7.915,-12.734 7.915,-12.734", style: {
  fill: "#101800",
  fillOpacity: 0,
  stroke: "#cc00cf",
  strokeWidth: 3
} }), /* @__PURE__ */ React.createElement("path", { d: "M36.867,70.213c2.115,1.762 10.926,3.243 10.855,5.992c-0.07,2.748 -8.951,3.101 -12.969,-0.212c-4.018,-3.313 -7.33,-10.784 -5.357,-11.982c1.974,-1.199 5.357,4.44 7.471,6.202Z", style: {
  fill: "#101800",
  fillOpacity: 0,
  stroke: "#cc00cf",
  strokeWidth: 3
} })));
export default SvgBaronTerritorial;
