import { deleteOverlayObject, setOverlayObject } from "@shared/Map/actions";
import { useMapFrameSnap } from "@shared/Map/frame_hooks";
import { LayerID, MapObject } from "@shared/Map/types";
import { toolState, uiState } from "@shared/states/uiStates";
import React, { FC, RefObject } from "react";
import { useSnapshot } from "valtio";
import { ViewerRef, Viewport } from "./Viewer";
import { useToolCanvas } from "./hooks";
import { Champion } from "@shared/Map/objects/shapes/Champion";
import { StructuresLayer } from "@shared/Map/StructuresLayer";
import { MapTool } from "@shared/types/tools";
import { PathTool } from "@shared/Map/tools/PathTool";
import { Path } from "@shared/Map/objects/shapes/Path";
import { Text } from "@shared/Map/objects/shapes/Text";
import { WardTool } from "@shared/Map/tools/WardTool";
import { Ward } from "@shared/Map/objects/shapes/Ward";
import { EraserTool } from "@shared/Map/tools/EraserTool";
import { ShapeTool } from "@shared/Map/tools/ShapeTool";
import { Shape } from "@shared/Map/objects/shapes/Shape";
import { networkState } from "@shared/states/networkState";
import { Cursor } from "@shared/Map/objects/Cursor";
import { TextTool } from "@shared/Map/tools/TextTool";
import { ImageTool } from "@shared/Map/tools/ImageTool";
import { MapImage } from "@shared/Map/objects/shapes/MapImage";

interface SVGMapContentProps {
	viewerRef: RefObject<ViewerRef | null>;
	viewport: Viewport;
}

export const MapContent: FC<SVGMapContentProps> = (props) => {
	const toolSnap = useSnapshot(toolState);
	const uiSnap = useSnapshot(uiState);
	const frame = useMapFrameSnap(uiSnap.currentFrameIndex);
	const { connected, states, clientId } = useSnapshot(networkState);

	const toolCanvas = useToolCanvas(props.viewerRef, props.viewport);

	const handleUpdateObject = (obj: MapObject) => (update: Partial<MapObject>) => {
		setOverlayObject(uiState.currentFrameIndex, obj.layerId, obj.id, update);
	};

	const handleAddObject = (newObject: Partial<MapObject>) => {
		setOverlayObject(uiState.currentFrameIndex, newObject.layerId!, newObject.id!, newObject);
	};

	const handleRemoveObject = (layerId: LayerID, objectId: string) => {
		deleteOverlayObject(uiState.currentFrameIndex, layerId, objectId);
	};

	return (
		<>
			<StructuresLayer />
			{Object.values(frame?.champions?.objects || {}).map((obj) => {
				return <Champion champ={obj} key={obj.id} onUpdate={handleUpdateObject(obj)} />;
			})}
			{Object.values(frame?.arrows?.objects || {}).map((obj) => {
				return <Path object={obj} key={obj.id} onUpdate={handleUpdateObject(obj)} />;
			})}
			{Object.values(frame?.paths?.objects || {}).map((obj) => {
				return <Path object={obj} key={obj.id} onUpdate={handleUpdateObject(obj)} />;
			})}
			{toolSnap.selectedTool === MapTool.WARD && (
				<WardTool toolCanvas={toolCanvas} onAdd={handleAddObject} />
			)}
			{Object.values(frame?.wards?.objects || {}).map((obj) => {
				return <Ward ward={obj} key={obj.id} onUpdate={handleUpdateObject(obj)} />;
			})}
			{toolSnap.selectedTool === MapTool.SHAPE && (
				<ShapeTool toolCanvas={toolCanvas} onAdd={handleAddObject} />
			)}
			{toolSnap.selectedTool === MapTool.IMAGE && (
				<ImageTool toolCanvas={toolCanvas} onAdd={handleAddObject} />
			)}
			{Object.values(frame?.shapes?.objects || {}).map((obj) => {
				return <Shape shape={obj} key={obj.id} onUpdate={handleUpdateObject(obj)} />;
			})}
			{Object.values(frame?.images?.objects || {}).map((obj) => {
				return <MapImage image={obj} key={obj.id} onUpdate={handleUpdateObject(obj)} />;
			})}
			{toolSnap.selectedTool === MapTool.TEXT && (
				<TextTool toolCanvas={toolCanvas} onAdd={handleAddObject} />
			)}
			{Object.values(frame?.texts?.objects || {}).map((obj) => {
				return <Text obj={obj} key={obj.id} onUpdate={handleUpdateObject(obj)} />;
			})}
			{toolSnap.selectedTool === MapTool.PENCIL && (
				<PathTool toolCanvas={toolCanvas} onAdd={handleAddObject} />
			)}
			{toolSnap.selectedTool === MapTool.ERASER && (
				<EraserTool toolCanvas={toolCanvas} onRemove={handleRemoveObject} />
			)}

			{connected &&
				states
					.filter((v) => v.clientId !== clientId)
					.map((v) => (
						<Cursor
							key={v.clientId}
							x={v.position.x}
							y={v.position.y}
							color={v.color}
						/>
					))}
		</>
	);
};
