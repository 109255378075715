import * as React from "react";
const SvgMountain = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 100 100", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeLinejoin: "round",
  strokeMiterlimit: 1.5
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,-125,0)" }, /* @__PURE__ */ React.createElement("g", { id: "mountain", transform: "matrix(14.641,0,0,11.5354,-87.1795,-120.202)" }, /* @__PURE__ */ React.createElement("rect", { x: 14.492, y: 10.42, width: 6.83, height: 8.669, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { id: "Layer1", transform: "matrix(0.0683012,0,0,0.08669,14.4921,10.4203)" }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1.36982,0,0,1.36982,-18.4684,-18.0006)" }, /* @__PURE__ */ React.createElement("path", { d: "M46,16L54,16L60,24L52.032,65L48,65L39.986,24L46,16Z", style: {
  fill: "none",
  stroke: "rgb(155,115,66)",
  strokeWidth: "2.19px"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(1.36982,0,0,1.36982,-18.4684,-18.0006)" }, /* @__PURE__ */ React.createElement("path", { d: "M31.638,33.045L44.505,73.833L44.505,81.347L38.794,80.355L26.032,64.411L26.152,40.528L31.638,33.045Z", style: {
  fill: "none",
  stroke: "rgb(155,115,66)",
  strokeWidth: "2.19px"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(-1.36982,0,0,1.36982,118.345,-18.0006)" }, /* @__PURE__ */ React.createElement("path", { d: "M31.638,33.045L44.505,73.833L44.505,81.347L38.794,80.355L26.032,64.411L26.152,40.528L31.638,33.045Z", style: {
  fill: "none",
  stroke: "rgb(155,115,66)",
  strokeWidth: "2.19px"
} }))))));
export default SvgMountain;
