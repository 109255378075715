import * as React from "react";
const SvgAtakhanPositionBottom = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 100 100", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinejoin: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,-815,-170)" }, /* @__PURE__ */ React.createElement("g", { id: "atakhan-position-bottom", transform: "matrix(14.641,0,0,11.5354,602.821,49.798)" }, /* @__PURE__ */ React.createElement("rect", { x: 14.492, y: 10.42, width: 6.83, height: 8.669, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { transform: "matrix(-0.0683012,-1.06165e-17,8.36449e-18,-0.08669,69.1331,33.8266)" }, /* @__PURE__ */ React.createElement("g", { id: "Layer1" }, /* @__PURE__ */ React.createElement("path", { d: "M780,190L720,190L720,250L780,190Z", style: {
  fill: "white"
} }))))));
export default SvgAtakhanPositionBottom;
