import React from "react";
import { platformState, uiState } from "@shared/states/uiStates";
import { Modal } from "@shared/modals/Modal";
import wards_043 from "./images/043_wards.jpg";
import framebar_045 from "./images/045_framebar.jpg";
import text_047 from "./images/047_text.jpg";
import pencil_108 from "./images/108_pencil.jpg";
import map_110 from "./images/110_map.jpg";
import { useSnapshot } from "valtio";

export interface ChangelogModalProps {}

const ChangelogModal: React.FC<ChangelogModalProps> = (props) => {
	const platformSnap = useSnapshot(platformState);

	const handleClose = () => {
		uiState.modal = undefined;
	};

	if (platformSnap.platform === "desktop") {
		return null;
	}

	return (
		<Modal title={"Changelog"} onClose={handleClose}>
			<div className={"max-h-[500px] h-screen w-[500px] text-slate-50 relative"}>
				<div className={"absolute inset-0 overflow-y-auto flex flex-col gap-8"}>
					<div>
						<div className={"flex items-center gap-2 mb-2"}>
							<div className={"rounded-xl px-2 uppercase bg-emerald-600 text-sm"}>
								New
							</div>
							<h1 className={"font-bold text-2xl"}>Version 1.1.0</h1>
						</div>
						<img
							src={map_110}
							alt={"A screenshot showing the new map"}
							className={
								"overflow-hidden rounded border border-black m-2 w-10/12 align-middle"
							}
						/>
						<ul className={"list-disc"}>
							<li className={"ml-8"}>New Noxus map images</li>
							<li className={"ml-8"}>
								Added Positional support for Atakhan (type does not work)
							</li>
							<li className={"ml-8"}>Fix for respawning Nexus towers</li>
						</ul>
					</div>
					<div>
						<div className={"flex items-center gap-2 mb-2"}>
							<h1 className={"font-bold text-2xl"}>Version 1.0.8</h1>
						</div>
						<img
							src={pencil_108}
							alt={"A screenshot showing the text functionality"}
							className={
								"overflow-hidden rounded border border-black m-2 w-6/12 align-middle"
							}
						/>
						<ul className={"list-disc"}>
							<li className={"ml-8"}>Line and arrows are now under the same tool</li>
							<li className={"ml-8"}>
								You can now load Flex Q games from the import modal
							</li>
							<li className={"ml-8"}>Images have been optimized to load faster</li>
						</ul>
					</div>
					<div>
						<div className={"flex items-center gap-2 mb-2"}>
							<h1 className={"font-bold text-2xl"}>Version 1.0.0</h1>
						</div>
						<img
							src={text_047}
							alt={"A screenshot showing the text functionality"}
							className={
								"overflow-hidden rounded border border-black m-2 w-10/12 align-middle"
							}
						/>
						<ul className={"list-disc"}>
							<li className={"ml-8"}>LoLPlanner is officially out of Beta!</li>
							<li className={"ml-8"}>
								You can now import GRID games with ward placements and 5 second
								frame resolution!
							</li>
						</ul>
					</div>
					<div>
						<div className={"flex items-center gap-2 mb-2"}>
							<h1 className={"font-bold text-xl"}>Version 0.5.0</h1>
						</div>
						<img
							src={text_047}
							alt={"A screenshot showing the text functionality"}
							className={
								"overflow-hidden rounded border border-black m-2 w-10/12 align-middle"
							}
						/>
						<ul className={"list-disc"}>
							<li className={"ml-8"}>You can now add text to the map!</li>
							<li className={"ml-8"}>
								Added button to toggle visibility on a whole team
							</li>
							<li className={"ml-8"}>
								Improve speed to the visibility toggle in sync mode
							</li>
							<li className={"ml-8"}>
								Panning the map can now be done using the middle mouse wheel or the
								dedicated tool.
							</li>
							<li className={"ml-8"}>
								Importing Summoners now requires the use of the RiotID in the format
								GameName#1234
							</li>
						</ul>
					</div>
					<div>
						<div className={"flex items-center gap-2 mb-2"}>
							<h1 className={"font-bold text-xl"}>Version 0.4.6</h1>
						</div>
						<ul className={"list-disc"}>
							<li className={"ml-8"}>Adjusted position of structures on the map.</li>
							<li className={"ml-8"}>Fixed a crash on games that failed to start.</li>
							<li className={"ml-8"}>Fixed a crash when using the Eraser tool.</li>
							<li className={"ml-8"}>
								Fixed a crash when failing to load game history.
							</li>
						</ul>
					</div>
					<div>
						<div className={"flex items-center gap-2 mb-2"}>
							<h1 className={"font-bold text-xl"}>Version 0.4.5</h1>
						</div>
						<img
							src={framebar_045}
							alt={"A screenshot "}
							className={"overflow-hidden rounded border border-black m-2 w-6/12"}
						/>
						<ul className={"list-disc"}>
							<li className={"ml-8"}>
								Improved Frames bar, shows better where you are and the time
							</li>
							<li className={"ml-8"}>Inhibitors respawn correctly after 5 minutes</li>
						</ul>
					</div>
					<div>
						<div className={"flex items-center gap-2 mb-2"}>
							<h1 className={"font-bold text-xl"}>Version 0.4.3</h1>
						</div>
						<img
							src={wards_043}
							alt={"A screenshot of the new wards panel"}
							className={"overflow-hidden rounded border border-black m-2 w-11/12"}
						/>
						<ul className={"list-disc"}>
							<li className={"ml-8"}>Added wards panel</li>
							<li className={"ml-8"}>Add text to your wards</li>
							<li className={"ml-8"}>Added this changelog modal :)</li>
						</ul>
					</div>
					<div>
						<div className={"flex items-center gap-2 mb-2"}>
							<h1 className={"font-bold text-xl"}>Version 0.4.2</h1>
						</div>
						<ul className={"list-disc"}>
							<li className={"ml-8"}>
								Champions are now rendered correctly when saving images
							</li>
						</ul>
					</div>
					<div>
						<h1 className={"font-bold text-xl mb-2"}>Version 0.4.1</h1>
						<ul className={"list-disc"}>
							<li className={"ml-8"}>Update Rift maps</li>
							<li className={"ml-8"}>Select the baron map type</li>
						</ul>
					</div>
					<div>
						<h1 className={"font-bold text-xl mb-2"}>Version 0.4.0</h1>
						<ul className={"list-disc"}>
							<li className={"ml-8"}>Reworked map engine</li>
							<li className={"ml-8"}>Download images of your map</li>
							<li className={"ml-8"}>Fixed issues with Brave</li>
						</ul>
					</div>
				</div>
			</div>
		</Modal>
	);
};

export { ChangelogModal };
