import React from "react";
import perryLogo from "./ads/perry.webp";
import { useSnapshot } from "valtio";
import { platformState } from "@shared/states/uiStates";

export interface TopAdBannerProps {
	place: string;
}

const TopAdBanner: React.FC<TopAdBannerProps> = (props) => {
	const platformSnap = useSnapshot(platformState);
	if (platformSnap.platform === "desktop") {
		return null;
	}

	return (
		<a href={"https://url.duiker101.net/perryjg" + props.place}>
			<div
				className={
					"flex items-center rounded bg-slate-600 mb-2 p-2 gap-2 border border-slate-400 relative hover:brightness-125 cursor-pointer"
				}
			>
				<img src={perryLogo} className={"h-12 w-auto rounded"} />
				<div className={"flex-1 text-white"}>
					<div className={"text-xl"}>Climb with Coaching</div>
					<div className={"opacity-50 text-sm"}>
						Learn how to 1v9 in the Jungle with Perry
					</div>
				</div>
				<div className={"absolute right-2 bottom-0 text-sm text-white"}>Ad</div>
			</div>
		</a>
	);
};

export { TopAdBanner };
