import React, { useState } from "react";
import { useSnapshot } from "valtio";
import { toolState, uiState } from "@shared/states/uiStates";
import { MapTool } from "@shared/types/tools";
import { Popover } from "react-tiny-popover";
import { BASE_COLORS, ColorBlock } from "@shared/Toolbar/ToolColorPicker";
import { setOverlayObject } from "@shared/Map/actions";

export interface TextPopupProps {}

const TextPopup: React.FC<TextPopupProps> = (props) => {
	const [isOpen, setIsOpen] = useState(false);
	const toolSnap = useSnapshot(toolState);
	const toolProps = toolSnap.properties[MapTool.TEXT];
	const currentColor = toolProps.color;

	const handleSelectColor = (color: string) => {
		toolState.properties[MapTool.TEXT].color = color;
		setIsOpen(false);

		const { editingId } = toolProps;
		if (editingId) {
			setOverlayObject(uiState.currentFrameIndex, "texts", editingId, { color });
		}
	};

	const handleChangeFontSize = (fontSize: number) => {
		toolState.properties[MapTool.TEXT].fontSize = fontSize;
		const { editingId } = toolProps;
		if (editingId) {
			setOverlayObject(uiState.currentFrameIndex, "texts", editingId, { fontSize });
		}
	};

	return (
		<>
			<Popover
				isOpen={isOpen}
				positions={["right"]}
				content={() => (
					<div
						className={
							"p-2 bg-slate-600 border border-black drop-shadow-md rounded flex flex-col gap-2"
						}
					>
						<input
							type={"range"}
							max={30}
							min={10}
							step={1}
							value={toolProps.fontSize}
							onChange={(e) => handleChangeFontSize(e.currentTarget.valueAsNumber)}
						/>
						<ColorsSelector color={toolProps.color} onSelect={handleSelectColor} />
					</div>
				)}
				onClickOutside={() => setIsOpen(false)}
			>
				<div className={"absolute -right-2 -bottom-2"}>
					<div
						onClick={() => setIsOpen(!isOpen)}
						className={
							"border border-slate-400 w-3 h-3 rounded-full m-2 hover:cursor-pointer"
						}
						style={{ background: currentColor }}
					></div>
				</div>
			</Popover>
		</>
	);
};

export const ColorsSelector: React.FC<{ color: string; onSelect: (color: string) => void }> = (
	props,
) => {
	return (
		<div className={"flex gap-2"}>
			{BASE_COLORS.map((c) => (
				<ColorBlock key={c} color={c} onClick={props.onSelect} />
			))}
		</div>
	);
};

// interface ShapeSelectorProps {
// 	selectedShape: ShapeObject["shape"];
// 	onSelectShape: (shape: ShapeObject["shape"]) => void;
// }

// const ShapeSelector: React.FC<ShapeSelectorProps> = (props) => {
// 	return (
// 		<div className={"flex gap-1 w-64 flex-wrap "}>
// 			{ALL_SHAPES.map((s) => (
// 				<div
// 					key={s}
// 					onClick={() => props.onSelectShape(s)}
// 					className={classNames(
// 						"rounded hover:bg-slate-500 hover:cursor-pointer items-center flex justify-center",
// 						{ "bg-slate-500": props.selectedShape == s },
// 					)}
// 				>
// 					<svg width={32} height={32} viewBox={"0 0 32 32"}>
// 						<ShapesFactory shape={s} color={"white"} />
// 					</svg>
// 				</div>
// 			))}
// 		</div>
// 	);
// };

export { TextPopup };
