import { deepmerge } from "deepmerge-ts";
import { useMemo } from "react";
import { useSnapshot } from "valtio";
import default_overlay from "../states/default_overlay.json";
import { useMapOverlayFrameSnapshot } from "../states/frameState";
import { inGameState } from "../states/inGameStates";
import { matchState } from "../states/uiStates";
import { AtakhanType, BaronTerrainType, DragonType, MapFrame } from "../types/map";
import { EliteMonsterKillEvent, MatchFrame, MatchParticipant } from "../types/riot";
import { pickle } from "../utils";
import { convertMatchFrameToMapFrame } from "@shared/Map/utils/matchUtils";
import { useRelativeTimestamp } from "@shared/hooks";

export function getOrCreateMinimapObject(objectId: string, obj?: any) {
	if (!inGameState.frame.arrows) inGameState.frame.arrows = { id: "arrows", objects: {} };

	const layer = inGameState.frame.arrows;
	if (obj) {
		layer.objects[objectId] = obj;
	}

	return layer.objects[objectId];
}

export function useMatchSnap() {
	const matchSnap = useSnapshot(matchState);
	return matchSnap.match;
}

export function useTimelineFrame(frameIndex: number): Maybe<MatchFrame> {
	const matchSnap = useMatchSnap();

	return useMemo(() => {
		if (!matchSnap) return null;
		const timelineFrames = matchSnap.timeline.frames;
		return timelineFrames[frameIndex] || null;
	}, [matchSnap, frameIndex]);
}

export function useTimelineMapFrame(frameIndex: number): Maybe<MapFrame> {
	const matchSnap = useMatchSnap();

	return useMemo(() => {
		if (!matchSnap) return pickle(default_overlay) as MapFrame;

		return convertMatchFrameToMapFrame(matchSnap, frameIndex);
	}, [frameIndex, matchSnap]);
}

// The final MapFrame to Render
export function useMapFrameSnap(frameIndex: number): MapFrame {
	const overlayFramesSnap = useMapOverlayFrameSnapshot();

	const frameSnap = overlayFramesSnap.frames?.[frameIndex];
	const overlayFrame = pickle(frameSnap) || { frameIndex };

	const timelineMapFrame = useTimelineMapFrame(frameIndex);

	return useMemo(() => {
		if (timelineMapFrame) {
			return deepmerge(timelineMapFrame, overlayFrame);
		} else {
			return overlayFrame;
		}
	}, [timelineMapFrame, overlayFrame]);
}

export function useFrameDragon(frameIndex: number): DragonType {
	// const matchSnap = useSnapshot(matchState);
	const matchSnap = useMatchSnap();

	const overlayFramesSnap = useMapOverlayFrameSnapshot();
	const frameSnap = overlayFramesSnap.frames?.[frameIndex];
	const overlayFrame = pickle(frameSnap);

	const timelineMapFrameDragon = useMemo(() => {
		const dragonMap: Record<
			Exclude<EliteMonsterKillEvent["monsterSubType"], undefined>,
			DragonType
		> = {
			EARTH_DRAGON: "mountain",
			CHEMTECH_DRAGON: "chemtech",
			FIRE_DRAGON: "fire",
			HEXTECH_DRAGON: "hextech",
			AIR_DRAGON: "wind",
			WATER_DRAGON: "ocean",
			ELDER_DRAGON: "normal",
		};

		if (matchSnap?.timeline) {
			let killedAtFrame = 0;
			let killCount = 0;
			let dragonFound: DragonType = "normal";
			let currentFrameI = 0;
			for (const frame of matchSnap.timeline.frames) {
				for (const event of frame.events) {
					if (
						event.type == "ELITE_MONSTER_KILL" &&
						event.monsterType == "DRAGON" &&
						event.monsterSubType
					) {
						killCount++;
						if (killCount === 3) {
							killedAtFrame = currentFrameI;
							dragonFound = dragonMap[event.monsterSubType];
						}
					}
				}
				currentFrameI++;
			}

			return frameIndex >= killedAtFrame ? dragonFound : "normal";
		}

		return "normal";
	}, [matchSnap, frameIndex]);

	return overlayFrame?.dragon || timelineMapFrameDragon;
}

export function useFrameBaronTerrain(frameIndex: number): BaronTerrainType | null {
	const matchSnap = useMatchSnap();

	const overlayFramesSnap = useMapOverlayFrameSnapshot();
	const frameSnap = overlayFramesSnap.frames?.[frameIndex];

	const time = useRelativeTimestamp(matchSnap?.timeline.frames[frameIndex].timestamp || 0);

	const timelineMapFrameBaronTerrain = useMemo((): BaronTerrainType | null => {
		if (!matchSnap?.timeline || time.minutes >= 20) {
			return overlayFramesSnap.frames?.[0]?.baronTerrain || null;
		}

		return null;
	}, [matchSnap, frameSnap, frameIndex, overlayFramesSnap]);

	return timelineMapFrameBaronTerrain;
}

export function useFrameAtakhan(frameIndex: number): AtakhanType | null {
	const matchSnap = useMatchSnap();

	const overlayFramesSnap = useMapOverlayFrameSnapshot();
	// Overlay Atakhan always on layer 0 so that it changes for the whole game
	const frameSnap = overlayFramesSnap.frames?.[0];
	const overlayFrame = pickle(frameSnap);
	const time = useRelativeTimestamp(matchSnap?.timeline.frames[frameIndex].timestamp || 0);

	const timelineMapFrameAtakhan = useMemo(() => {
		if (!matchSnap?.timeline || time.minutes < 20) {
			return null;
		}

		let killedAtFrame = 0;
		let currentFrameI = 0;
		let position: "top" | "bottom" | null = null;
		frameLoop: for (const frame of matchSnap.timeline.frames) {
			for (const event of frame.events) {
				if (event.type == "ELITE_MONSTER_KILL" && event.monsterType == "ATAKHAN") {
					killedAtFrame = currentFrameI;
					position = event.position.y > 3000 ? "top" : "bottom";
					break frameLoop;
				}
			}
			currentFrameI++;
		}

		if (killedAtFrame) {
			const alive = frameIndex < killedAtFrame;
			return {
				position,
				alive,
				type: "voracious",
			} as AtakhanType;
		}

		return null;
	}, [matchSnap, frameIndex, time]);

	return overlayFrame?.atakhan || timelineMapFrameAtakhan;
}

// todo d1 participantId should be consistent
export function useParticipant(participantId: Maybe<number | string>) {
	const matchSnap = useMatchSnap();

	return useMemo(() => {
		if (!matchSnap?.summary || !participantId) return null;

		return (
			matchSnap.summary.participants?.find(
				(p: MatchParticipant) => "" + participantId === "" + p.participantId,
			) || null
		);
	}, [matchSnap, participantId]);
}
