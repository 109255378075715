import * as React from "react";
const SvgAtakhanR = (props) => /* @__PURE__ */ React.createElement("svg", { width: "100%", height: "100%", viewBox: "0 0 100 100", xmlns: "http://www.w3.org/2000/svg", xmlnsXlink: "http://www.w3.org/1999/xlink", xmlSpace: "preserve", "xmlns:serif": "http://www.serif.com/", style: {
  fillRule: "evenodd",
  clipRule: "evenodd",
  strokeLinecap: "round",
  strokeMiterlimit: 2
}, ...props }, /* @__PURE__ */ React.createElement("g", { transform: "matrix(1,0,0,1,-582,-170)" }, /* @__PURE__ */ React.createElement("g", { id: "atakhan-r", transform: "matrix(14.641,0,0,11.5354,369.821,49.798)" }, /* @__PURE__ */ React.createElement("rect", { x: 14.492, y: 10.42, width: 6.83, height: 8.669, style: {
  fill: "none"
} }), /* @__PURE__ */ React.createElement("g", { id: "Layer1" }), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.0896984,0,0,0.113848,-27.2033,-10.2504)" }, /* @__PURE__ */ React.createElement("path", { d: "M502.932,187.253L508.715,199.38L502.932,211.741L497.054,198.331L502.932,187.253Z", style: {
  fill: "rgb(96,13,189)",
  stroke: "black",
  strokeWidth: "1.52px"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.0896984,0,0,0.113848,-27.2033,-10.2504)" }, /* @__PURE__ */ React.createElement("path", { d: "M509.881,208.476C509.151,202.768 513.682,195.051 519.326,196.931C519.842,196.416 518.789,201.013 518.789,201.013C518.789,201.013 522.587,203.079 523.469,204.994C525.416,209.219 524.457,215.589 524.457,215.589C522.366,210.878 519.829,206.613 515.245,204.394L509.881,208.476Z", style: {
  fill: "rgb(96,13,189)",
  stroke: "black",
  strokeWidth: "1.52px"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(-0.0896984,0,0,0.113848,63.0168,-10.2504)" }, /* @__PURE__ */ React.createElement("path", { d: "M509.881,208.476C509.151,202.768 513.682,195.051 519.326,196.931C519.842,196.416 518.789,201.013 518.789,201.013C518.789,201.013 522.587,203.079 523.469,204.994C525.416,209.219 524.457,215.589 524.457,215.589C522.366,210.878 519.829,206.613 515.245,204.394L509.881,208.476Z", style: {
  fill: "rgb(96,13,189)",
  stroke: "black",
  strokeWidth: "1.52px"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(0.0896984,0,0,0.113848,-27.2135,-10.1365)" }, /* @__PURE__ */ React.createElement("path", { d: "M504.114,220.09L517.248,211.741C519.511,213.089 521.534,215.633 523.174,220.09C528.652,216.808 533.323,215.139 536.934,215.589C529.866,226.179 520.608,235.735 514.662,248.123C513.216,243.837 512.998,239.427 514.662,234.83C511.355,237.993 508.664,240.152 504.114,241.761L504.114,220.09ZM508.445,230.236L519.042,225.161L519.192,220.534L515.46,220.757L508.669,226.429L508.445,230.236Z", style: {
  fill: "rgb(96,13,189)",
  stroke: "black",
  strokeWidth: "1.52px"
} })), /* @__PURE__ */ React.createElement("g", { transform: "matrix(-0.0896984,0,0,0.113848,63.0168,-10.1365)" }, /* @__PURE__ */ React.createElement("path", { d: "M502.864,220.09L502.816,241.79C507.367,240.181 511.355,237.993 514.662,234.83C512.998,239.427 513.216,243.837 514.662,248.123C520.608,235.735 529.866,226.179 536.934,215.589C533.323,215.139 528.652,216.808 523.174,220.09C521.534,215.633 519.511,213.089 517.248,211.741L502.864,220.09ZM507.336,230.236L507.56,226.429L514.351,220.757L518.083,220.534L517.934,225.161L507.336,230.236Z", style: {
  fill: "rgb(96,13,189)",
  stroke: "black",
  strokeWidth: "1.52px"
} })))));
export default SvgAtakhanR;
